import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import '../components/index.css'
import SampleForm from '../components/Form'
import { Helmet } from 'react-helmet'

import linkedin from '../img/linkedin.svg'
import logoWhite from '../img/logo-white.svg'
// import kiplinger from '../img/kiplinger.png'
// import wired from '../img/wired.png'
// import wsj from '../img/wsj.png'
import logoIcon from '../img/logo-icon.svg'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      // header = (
      //   <h1
      //     style={{
      //       ...scale(1.5),
      //       marginBottom: rhythm(1.5),
      //       marginTop: 0,
      //     }}
      //   >
      //     <Link
      //       style={{
      //         boxShadow: `none`,
      //         textDecoration: `none`,
      //         color: `inherit`,
      //       }}
      //       to={`/`}
      //     >
      //       {title}
      //     </Link>
      //   </h1>
      // )
    } else {
      // header = (
      //   <h3
      //     style={{
      //       fontFamily: `Montserrat, sans-serif`,
      //       marginTop: 0,
      //     }}
      //   >
      //     <Link
      //       style={{
      //         boxShadow: `none`,
      //         textDecoration: `none`,
      //         color: `inherit`,
      //       }}
      //       to={`/`}
      //     >
      //       {title}
      //     </Link>
      //   </h3>
      // )
    }
    return (
      <div>
        <Header />
        <div>
          {header}
          {children}
          <div
            className="container-fluid contact-section text-white"
            id="contact"
          >
            <Helmet>
              <script type="text/javascript">
                {`
            (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
            vgo('setAccount', '652156855');
            vgo('setTrackByDefault', true);
            vgo('process');
          `}
              </script>
            </Helmet>
            <div className="container sub-container">
              <div className="row">
                <div className="col-md-4">
                  <img src={logoWhite} alt="Company Logo" />
                  <p className="mt-3 text-left">
                    We provide the technology, training and support our
                    customers need to harness the power of multispectral imaging
                    in their labs and facilities. Our team combines years of
                    experience in seed research, product design and industrial
                    automation to serve today’s agricultural operators.
                  </p>
                  <div className="d-none company-social text-left social-media-icons">
                    {/* <a href="/">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="/">
                      <img src={twitter} alt="Twitter" />
                    </a> */}
                    <a href="/">
                      <img src={linkedin} alt="LinkedIn" />
                    </a>
                    <div />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div />
                    <div />
                  </div>
                  <h4 className="text-white mt-4">Contact Info</h4>
                  <p className="mb-0 address">
                    <strong>Address:</strong> 1689 Skyway Drive, Suite 21
                    Longmont CO, 80504
                  </p>
                  <p className="mb-0 phone">
                    <strong>Phone:</strong>{' '}
                    <a href="tel:17206161044">+1 720-616-1044</a>
                  </p>
                  <p className="email">
                    <strong>Email:</strong>{' '}
                    <a href="mailto:taylor@getskywayanalytics.com">
                      taylor@getskywayanalytics.com
                    </a>
                    <br />
                  </p>
                  <h4 className="text-white mt-4">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/#about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/#technology">The Technology</Link>
                    </li>
                    <li>
                      <Link to="/#testimonial">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/#team">Our Teams</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 contact-clean">
                  <h2 className="text-center text-white">
                    <img src={logoIcon} className="logo-icon" alt="Logo Icon" />
                    Contact Us
                  </h2>
                  <SampleForm
                    form_name="Contact Form"
                    form_id="5dca84864fed617478ac3b0b"
                    form_classname="form-newsletter"
                  >
                    <div className="form-row">
                      <div className="col-12 mb-3">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            name="Email Address"
                            id="email_address"
                          />
                          <label htmlFor="email_address">
                            Enter Your Email Address
                          </label>
                        </div>
                        <small className="text-warning form-text mt-2">
                          We will never share your email with anyone else.
                        </small>
                      </div>
                      <div className="col-12 ">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="Name"
                            id="full_name"
                          />
                          <label htmlFor="full_name">Enter Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            rows="4"
                            className="form-control"
                            name="message"
                            id="message"
                          />
                          <label htmlFor="message">How can we help you?</label>
                        </div>
                      </div>
                      <div className="col-12 px-0">
                        <div className="webriq-recaptcha" />
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <button
                            className="btn-global d-block w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </SampleForm>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid footer-section text-white text-center">
            <div>
              <span className="spanYear">
                © {new Date().getFullYear()} Skyway Analytics. All rights
                reserved.
              </span>

              <span className="d-block">
                {' '}
                <span>Designed and Powered by</span>{' '}
                <a
                  href="https://www.webriq.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="WebriQ"
                >
                  WebriQ
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Layout
