import React from 'react'
import { Link } from 'gatsby'
import { Nav, Dropdown, NavItem } from 'react-bootstrap'
import './menu.css'

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="#aboutus">About Us</Link>
      </Nav.Item>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={Nav.Link}>Applications</Dropdown.Toggle>
        <Dropdown.Menu>
            <Link className="dropdown-item" to="/feed-analytics">Feed Analysis</Link>
            <Link className="dropdown-item" to="/seed-analytics">Seed Analysis</Link>
        </Dropdown.Menu>
      </Dropdown>
      <Nav.Item>
        <Link to="#technology">The Technology</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="#testimonial">Testimonials</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="#team">Our Team</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/faq">FAQ</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="#contact">Contact Us</Link>
      </Nav.Item>
      <button
        className="btn-global float-right mr-5 mt-3 sidebar-cta"
        type="button"
      >
        Get a Quote
      </button>

      {/* <Dropdown as={NavItem}>
        <Link variant="success" className="float-left" to="/">
          Dropdown Split Button
        </Link>
        <Dropdown.Toggle split id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Dropdown.Item hred="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item hred="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item hred="#/action-3">Something else</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </Nav>
  </div>
)
