import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import Modal from './Modal'
import styl from './header.module.css'
import logo from '../img/logo.svg'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div id="navbar" className={` ${styl.menuContainer}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <Link to="/" className="logomain">
                  <img src={logo} alt="Company Logo" />
              </Link>
            </div>

            <div className="col-6">
              <SideBarMenu />
              <Modal bsPrefix="btn-global float-right mr-5 nav-cta d-none d-md-inline" html="Get a Quote">
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-learn-more-website-faq-clone?month=2019-11"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
            </Modal>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
